@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.nmmc_blue_bg {
    /* background-color: #0c3624; */
    background-color: #1e4660;
    background-image: linear-gradient(to right, #1b6294, #054676);
}

.orange {
    color: #dd6101 !important;
}

.logo_in_circle {
    /* background-color: #fff;  */
    border-radius: 10px;
    width: 35%;
}
.logo_in_circle{
    width: 135px;
}
.f7{
    font-size: 14px !important;
}

.bg_light_white {
    /* background-color: #ffffff4f; */
    background-image: linear-gradient(#ffffff4f, rgba(56, 39, 81, 0.7));
}

.bordre_round {
    border-radius: 20px !important;
    /* filter: blur(1px); */
}

.geo_tag h6 {
    font-size: 14px !important;
}

/* for mobile */
@media screen and (max-width: 800px) {
    .mobile_none {
        display: none !important;
    }
}

.wardha_background {
    /* background: url(../../../public/images/Login_bg/wardha_bg.jpg); */
    /* background: url(../../../public/images/Login_bg/wardha3-01.jpg); */
    background: url(../../../public/Static/Assets/Img/bg_login1.png);
    background-repeat: no-repeat;
    background-size: 100% 105% !important;
}



.heading_font_size {
    font-size: 22px !important;
}

@media screen and (min-width: 800px) {
    .heading_font_size {
        font-size: 32px !important;
    }
}

.Center_item {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    height: 100vh !important;
    /* background: linear-gradient(rgba(56, 39, 81, 0.7), #39294E), url(./../../../../public/static/assets/Img/roadside-trees.jpg); */
    /* background: url(./../../../../public/static/assets/Img/roadside-trees.jpg); */
    /* background-image: linear-gradient(#113133, #264545, #C0B07C); */
    background-repeat: no-repeat;
    background-size: cover;
}
.btn_submit{
    background-image: linear-gradient(to right, #1b6294, #054676);
    color: white !important;
    border: 1px solid #054676 !important;
}
.Center_item_img {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

/* .img_position{
    position: absolute;
    width: 0%;
} */
/* for pc*/
@media screen and (min-width: 802px) {
    .pc_none {
        display: none !important;
    }
}

.header_img {
    width: 120px !important;
}

@media (min-width:992px) and (max-width:1200px) {
    .header_img {
        width: 5vw !important;
    }
}

.input-group-text {
    background-color: #ebebeb  !important;
    /* border: 1px solid red !important; */
    border: 1px solid #c7c1c1 !important;
    /* border-radius: 0 !important; */
}
input,
input::placeholder {
    font: 10px !important;
}